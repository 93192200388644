.product {
  &__header {
    flex-wrap: wrap;

    .image {
      align-self: flex-start;
      width: 24%;
      flex-shrink: 0;
      padding-bottom: 2em;
      @include shadow();
      text-align: center;

      @media screen and (max-width: $mobile-l) {
        width: 100%;

        img {
          max-width: 15em;
        }
      }
    }

    .description {
      color: $grey;
      width: 28%;
      flex-shrink: 0;
      border-right: 1px solid rgba(198,170,107,.3);
      margin-bottom: 1em;
      font-weight: 300;
      line-height: 1.35;

      @media screen and (max-width: $laptop-m) {
        width: 76%;
        border-right: none;
      }

      @media screen and (max-width: $mobile-l) {
        width: 100%;
      }
    }

    .tabs {
      width: 48%;
      flex-shrink: 0;

      @media screen and (max-width: $laptop-m) {
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: 1px solid rgba(198,170,107,.3);
        border-bottom: 1px solid rgba(198,170,107,.3);
      }
    }
  }

  &__privileges {
    margin-top: 4em;

    @media screen and (max-width: $laptop-s) {
      flex-wrap: wrap;
      margin-top: 2em;
    }

    &>.col {
      &:first-child {
        flex-shrink: 0;
        padding-left: 0;

        @media screen and (max-width: $laptop-s) {
          width: 100%;
          margin-bottom: 2em;
          padding-left: 1em;

          .btn-gold {
            display: table;
            margin: 0 auto;

            &:before {
              display: none;
            }
          }
        }
      }
    }

    .privileges {
      @include col(6, .5em);

      @media screen and (max-width: $laptop-m) {
        @include col(3);
      }

      @media screen and (max-width: $tablet) {
        @include col(2);
      }

      img {
        width: 24/40 + em;
      }

      i {
        display: flex;
        margin: 0 auto (16/40 + em);
        font-size: 2.5em;
        color: #fff;
        width: 77/40 + em;
        height: 77/40 + em;
        border-radius: 50%;
        background: $color-primary;
        align-items: center;
        justify-content: center;
      }

      p {
        font-weight: 300;
        text-align: center;
      }
    }
  }
}