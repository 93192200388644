.menu-btn {
  position: relative;
  padding: 1em;
  cursor: pointer;

  &:hover {
    .nav-icon span {
      background: $color-secondary;
    }
  }

  .nav-icon {
    width: 28px;
    height: 13px;
    transition: .5s ease-in-out;
    position: relative;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #000;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      //transition: .25s ease-in-out;
      transition: .3s;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 5px;
      }

      &:nth-child(4) {
        top: 10px;
      }
    }

    &.opened span {
      background: #000;

      &:nth-child(1) {
        top: 5px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 5px;
        width: 0;
        left: 50%;
      }
    }
  }
}