.form {
  max-width: 100%;

  p {
    color: $grey;
    font-size: .875em;
  }

  .input-group {
    margin-bottom: 1em;

    label {
      font-size: .875em;
      font-weight: 500;
      margin-bottom: .25em;
      display: block;
    }

    input, textarea {
      padding: 1em 1.5em;
      border: 1px solid #ccc;
      width: 100%;
      outline: none !important;

      &:focus {
        border-color: #666;
      }

      &.green {
        border-color: green !important;
      }

      &.red {
        border-color: red !important;
      }
    }
  }

  .btn {
    display: table;
    margin: 0 auto;
    font: bold (28/16 + em) "Bebas Neue";
    text-transform: uppercase;
    color: $color-secondary;
    transition: .3s;

    &:hover {
      color: $color-secondary - 20;
    }
  }
}