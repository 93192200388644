html {
  font-size: 16px;
  font-family: 'HelveticaNeueCyr', sans-serif;

  @media screen and (min-width: $laptop-l + 1) {
    font-size: 18px;
  }

  body {
    margin: 0;
    color: #000;
    background: #edecec;
  }
}

*, ::after, ::before {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0 1em;
  width: 100%;
}

.container:not(.fluid) {
  max-width: calc(1280em/16 + 2em);

  @media screen and (max-width: $laptop-m) {
    max-width: calc(992px + 2em);
  }

  @media screen and (max-width: $laptop-s) {
    max-width: calc(768px + 2em);
  }

  @media screen and (max-width: $tablet) {
    max-width: calc(480px + 2em);
  }
}

.row {
  display: flex;
  margin-left: -1em;
  margin-right: -1em;

  &>.col {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }
}