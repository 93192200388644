.first-display {
  display: flex;

  @media screen and (max-width: $laptop-s) {
    flex-direction: column;
  }

  header {
    margin-bottom: 78/16 + em;

    @media screen and (max-width: $tablet) {
      margin-bottom: .875em;
    }
  }

  .main {
    width: 100%;

    .container {
      //max-width: calc(1282em/16 - 407em/16 + (100vw - 1282em/16)/2);
      margin-right: 0;
    }
  }

  .right-block {
    width: 407/16 + em;
    flex-shrink: 0;
    display: flex;
    height: 100vh;
    flex-direction: column;

    @media screen and (max-width: $laptop-s) {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .top {
      height: 100%;
      background: url(/assets/img/first/bg.png) center center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 1em;
      padding: 41/16 + em;
      align-items: center;

      @media screen and (max-width: $laptop-s) {
        width: 100%;
        margin-left: 0;
      }

      a {
        display: flex;
        align-items: center;
        color: #fff;
        transition: .3s;
        min-width: 208/16 + em;
        font-weight: 200;
        padding: (7/16 + em) (14/16 + em);

        img {
          width: 28/16 + em;
          margin-right: 1em;
        }

        &:not(:last-child) {
          margin-bottom: 1.5em;
        }

        &:first-child {
          background: #c5a96b;

          &:hover {
            background: #c5a96b - 20;
          }
        }

        &:last-child {
          background: #979798;

          &:hover {
            background: #979798 - 20;
          }
        }
      }
    }

    .contacts-blue {
      flex-shrink: 0;

      @media screen and (max-width: $tablet) {
        display: none;
      }

      &:after {
        top: auto;
        bottom: 100%;
        border-bottom: 1em solid #498286;
        border-top: none;
      }
    }
  }

  h1 {
    margin-top: 0;
  }
}