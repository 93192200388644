//Размеры экранов
$laptop-l: 1440px;
$laptop-m: 1200px;
$laptop-s: 1024px;
$tablet: 767px;
$mobile-l: 480px;
$mobile-m: 375px;
$mobile-s: 320px;

$color-primary: #8cc7cb;
$color-secondary: #c6a96b;
$grey: #626262;