//Свойство Object-fit
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin col($col, $space: null) {
  flex-wrap: wrap;
  display: flex;

  &>* {
    width: 100%/$col;
  }

  @if ($space) {
    margin: -$space;

    &>* {
      padding: $space;
    }
  }
}

@mixin shadow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 20%;
    background: radial-gradient(rgba(0,0,0,0.37), transparent 70%);
    border-radius: 100%;
    z-index: -1;
    transition: .3s;
  }
}