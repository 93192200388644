//Bebas
@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/Bebas/BebasNeueRegular.eot');
  src: local('/assets/fonts/Bebas/Bebas Neue Regular'), local('BebasNeueRegular'),
  url('/assets/fonts/Bebas/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Bebas/BebasNeueRegular.woff') format('woff'),
  url('/assets/fonts/Bebas/BebasNeueRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/Bebas/BebasNeueBold.eot');
  src: local('/assets/fonts/Bebas/Bebas Neue Bold'), local('BebasNeueBold'),
  url('/assets/fonts/Bebas/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Bebas/BebasNeueBold.woff') format('woff'),
  url('/assets/fonts/Bebas/BebasNeueBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('/assets/fonts/Bebas/BebasNeueBook.eot');
  src: local('/assets/fonts/Bebas/Bebas Neue Book'), local('BebasNeueBook'),
  url('/assets/fonts/Bebas/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Bebas/BebasNeueBook.woff') format('woff'),
  url('/assets/fonts/Bebas/BebasNeueBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


//Helvetica
@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Medium.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Medium'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Medium.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Heavy.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Heavy'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Heavy.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Black.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Black'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Black.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-BlackItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-BlackItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-ThinItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-ThinItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-MediumItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-MediumItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-LightItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-LightItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-LightItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLight.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLight'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLight.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-BoldItalic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-BoldItalic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Italic.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Italic'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Italic.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Roman.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Roman'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Bold.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Bold'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Bold.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Light.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Light'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Light.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/assets/fonts/Helvetica/HelveticaNeueCyr-Thin.eot');
  src: local('/assets/fonts/Helvetica/HelveticaNeueCyr-Thin'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Thin.woff') format('woff'),
  url('/assets/fonts/Helvetica/HelveticaNeueCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?3gztko');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?3gztko#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?3gztko') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?3gztko') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?3gztko#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e90b";
}
.icon-chevron-down:before {
  content: "\e90a";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chevron-left:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e905";
}
.icon-add:before {
  content: "\e901";
}
.icon-list:before {
  content: "\e902";
}
.icon-material:before {
  content: "\e903";
}
.icon-people:before {
  content: "\e904";
}
.icon-globe:before {
  content: "\e900";
}
