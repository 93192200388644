body .fancybox-content {
  padding: 2em;
  width: 25em;
  max-width: 100%;

  @media screen and (max-width: $tablet) {
    padding: 2em 1.5em 1.5em;
  }

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 100%;
    height: auto;
    z-index: 1;
    display: none;
  }

  .success-message {
    display: none;
    opacity: 0;

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 3em;
      margin-right: 1em;
    }

    span {
      font-weight: 500;
    }
  }
}