.contacts-blue {
  background: $color-primary;
  padding: (27/16 + em) (71/16 + em) (35/16 + em) (65/16 + em);
  color: #fff;
  font-weight: 200;
  position: relative;
  line-height: 1.4;

  &:after {
    content: '';
    border-top: 1em solid #498286;
    border-left: 1em solid transparent;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 100%;
    left: 0;
  }
}