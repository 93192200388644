.locations {
  width: 100%;
  position: relative;

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      line-height: 0;

      &>div, .item {
        height: 100%;
      }

      &:nth-of-type(2n) {
        .item > div {
          height: 50%;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;

    &>div {
      flex: 1;
      position: relative;
      cursor: pointer;
      height: 100%;

      &:hover {
        h4 {
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
      display: block;
    }

    h4 {
      position: absolute;
      z-index: 1;
      background: $color-secondary;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      margin: 0;
      color: #fff;
      font-size: 2em;
      font-weight: 200;
      opacity: 0;
      transition: .3s linear;
      line-height: normal;

      @media screen and (max-width: $tablet) {
        opacity: .9 !important;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $color-primary;
    border-radius: 50%;
    color: #fff;
    font-size: 3em;
    cursor: pointer;
    height: 1.5em;
    line-height: 1.5em;
    width: 1.5em;
    text-align: center;
    transition: .3s;
    z-index: 2;

    @media screen and (max-width: $tablet) {
      font-size: 1.5em;
    }

    &:hover {
      background: $color-primary - 20;
    }

    &:first-child {
      left: 1em;
    }

    &:last-child {
      right: 1em;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
}