.circle-item {
  display: table;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: $tablet + 1) {
    &:hover {
      img {
        border-color: $color-secondary;
        transform: translateY(-1em);
      }

      .image:after {
        transform: translateX(-50%) scale(1.2);
        opacity: .5;
      }

      h3 {
        color: $color-secondary - 20;
      }
    }
  }

  .image {
    margin-bottom: 23/16 + em;
    margin-left: auto;
    margin-right: auto;
    display: table;
    @include shadow();

    @media screen and (max-width: $tablet) {
      margin-bottom: .5em;
    }
  }

  img {
    border: 1px dashed transparent;
    padding: 1em;
    border-radius: 50%;
    transition: .3s;
    width: 216/16 + em;
  }

  h3 {
    margin: 0;
    font: bold (28/16 + em) "Bebas Neue";
    text-transform: uppercase;
    color: $color-secondary;
    transition: .3s;
  }

  p {
    font-size: 1.125em;
    font-weight: 300;
    color: $grey;
    margin-top: 0;
  }
}