.tabs {
  &__titles {
    margin-bottom: 20/16 + em;

    span {
      font-size: 11/16 + em;
      color: $color-primary;
      transition: .3s;
      display: inline-block;
      margin-right: 1.2em;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $color-primary - 20;
      }

      &.active {
        color: $color-secondary;
      }
    }
  }

  &__contents {
    div {
      display: none;
      font-size: 11/16 + em;

      ul {
        margin: 0;
        column-count: 2;
        list-style: none;
        padding-left: 0;

        @media screen and (max-width: $mobile-l) {
          column-count: 1;
        }

        li {
          display: block;
          padding-left: 1em;
          line-height: 2.27;
          position: relative;

          &:before {
            content: '•';
            color: $color-secondary;
            margin-right: .5em;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}