.contacts {
  max-width: 70em;
  margin: 0 auto 4em;

  .row {
    justify-content: space-around;

    @media screen and (max-width: $tablet) {
      @include col(1, 1em);
    }

    .col {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        a[data-fancybox] {
          color: $color-primary;
          transition: .3s;
          text-transform: uppercase;

          &:hover {
            color: $color-primary - 20;
          }
        }
      }
    }
  }

  h4 {
    text-transform: uppercase;
    margin-top: 0;
    font-size: 1.25em;
    margin-bottom: 1em;
    font-weight: normal;
  }

  i {
    color: $grey;
    font-size: 1.5em;
    font-weight: lighter;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    border-radius: 50%;
    border: 2px solid;
    margin-right: .5em;
    opacity: .5;
  }
}