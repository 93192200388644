h1 {
  margin: (12/63 + em) 0 0;
  color: $color-primary;
  font-family: "Bebas Neue Book";
  font-size: 63/16 + em;
  text-align: center;
  font-weight: lighter;

  @media screen and (max-width: $laptop-s) {
    font-size: 3em;
  }

  @media screen and (max-width: $mobile-l) {
    font-size: 2.5em;
  }

  &+h4 {
    text-align: center;
    font-size: 1.125em;
    font-weight: 300;
    color: $grey;
  }
}

.page-title {
  margin-bottom: 64/16 + em;

  @media screen and (max-width: $tablet) {
    margin-bottom: 1em;
  }

  &.small-margin {
    margin-bottom: 38/16 + em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 1em;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 4/18 + em;
    margin-bottom: 0;
  }
}

.btn-gold {
  font-size: 22/16 + em;
  font-weight: bold;
  padding: (26/22 + em) (40/22 + em);
  background: $color-secondary;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  transition: .3s;

  @media screen and (max-width: $laptop-s) {
    font-size: 1em;
  }

  &:before {
    content: '';
    border-bottom: 1em solid #ab8124;
    border-right: 1em solid transparent;
    width: 1em;
    height: 1em;
    position: absolute;
    bottom: 100%;
    right: 0;
  }

  &:hover {
    background: $color-secondary - 20;
  }
}