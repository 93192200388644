.solutions {
  @include col(2, 1em);
  justify-content: space-around;
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $tablet) {
    @include col(1);
  }
}