header, .header {
  margin-bottom: 2.875em;

  @media screen and (max-width: $tablet) {
    margin-bottom: .875em;
  }

  .container > .row {
    align-items: flex-start;
  }

  &__left {
    @media screen and (max-width: $tablet) {
      width: 100%;
    }

    .row {
      align-items: center;

      @media screen and (max-width: $tablet) {
        flex-direction: row-reverse;
      }
    }
  }

  &__right {
    margin-left: auto;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    .contacts-blue {
      margin-right: -1em;
    }
  }

  .menu-btn {
    margin-right: 26/16 + em;
    margin-left: 42/16 + em;

    @media screen and (max-width: $tablet) {
      margin-left: 26/16 + em;
      margin-right: 42/16 + em;
    }
  }

  .logo {
    padding: (38/16 + em) 0;

    img {
      width: 198/16 + em;
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #edecec;
    z-index: 9;
    display: none;

    &.opened {
      nav {
        transform: scale(1);
        opacity: 1;

        @media screen and (max-width: $tablet) {
          transform: translateY(0);
        }
      }
    }

    i {
      position: absolute;
      z-index: 1;
      top: .6em;
      left: 1em;
      font-size: 1.75em;
      transition: .3s;
      cursor: pointer;
      padding: 1em;

      @media screen and (max-width: $tablet) {
        left: auto;
        right: 1em;
      }

      &:hover {
        color: $color-secondary;
      }
    }

    nav {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(.5);
      opacity: 0;
      transition: .5s;
      transition-delay: .15s;

      @media screen and (max-width: $tablet) {
        align-items: flex-start;
        padding-top: 7em;
        flex-direction: column;
        justify-content: space-between;
        transform: translateY(-5em);
        opacity: 1;
        transition-delay: 0s;

        @media screen and (max-width: $mobile-l) {
          padding-top: 4em;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      @media screen and (max-width: $tablet) {
        flex-wrap: wrap;
        padding: 2em;
      }

      li {
        display: flex;
        align-items: center;

        @media screen and (max-width: $tablet) {
          width: 100%;
          margin-bottom: 1em;
        }

        &:not(:last-child):after {
          content: '|';
          font-size: 3em;
          font-weight: 200;
          color: #ccc;
          vertical-align: middle;

          @media screen and (max-width: $tablet) {
            display: none;
          }
        }

        a {
          padding: 1em;
          display: inline-block;
          vertical-align: middle;
          text-transform: uppercase;
          font-size: 3em;
          color: $color-primary;
          transition: .3s;
          font-family: "Bebas Neue Book";

          @media screen and (max-width: $tablet) {
            padding: .25em 0;
          }

          @media screen and (max-width: $mobile-l) {
            font-size: 2.5em;
          }

          &:hover {
            color: $color-primary - 50;
          }
        }
      }
    }
  }

  .mob-contacts {
    background: $color-primary;
    color: #fff;
    padding: 2em;
    width: 100%;
    font-weight: 200;

    @media screen and (min-width: $tablet + 1) {
      display: none;
    }
  }
}